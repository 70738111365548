import EllingtonVillaCover from "../Images/CoverImages/EllingtonVillaCover_800.webp";
import SerenityWaterfrontCover from "../Images/CoverImages/SerenityWaterfrontCover_1000.webp";
import PalmJebelAliCover from "../Images/CoverImages/PalmJebelAliCover_800.webp";
import AvaCover from "../Images/CoverImages/AvaCover_800.webp";
import BayviewCover from "../Images/CoverImages/BayviewCover_800.webp";
import BugattiResidenceCover from "../Images/CoverImages/BugattiResidenceCover_800.webp";
import ComoResidencesCover from "../Images/CoverImages/ComoResidencesCover_800.webp";
import DistrictOneWestCover from "../Images/CoverImages/DistrictOneWestCover_800.webp";
import ElysianMansionCover from "../Images/CoverImages/ElysianMansionCover_800.webp";
import LamborghiniMansionCover from "../Images/CoverImages/LamborghiniMansionCover_1000.webp";
import LanaiIslandsCover from "../Images/CoverImages/LanaiIslandsCover_800.webp";
import OrlaCover from "../Images/CoverImages/OrlaCover_800.webp";
import SensoriaCover from "../Images/CoverImages/SensoriaCover_800.webp";
import SerenityMansionCover from "../Images/CoverImages/SerenityMansionCover_800.webp";
import SixSensesCover from "../Images/CoverImages/SixSensesCover_800.webp";
import VelaCover from "../Images/CoverImages/VelaCover_800.webp";
import UtopiaCover from "../Images/CoverImages/UtopiaCover_800.webp";
import ArmaniCover from "../Images/CoverImages/ArmaniCover_800.webp";
import ElaCover from "../Images/CoverImages/ElaCover_800.webp";

const data = [
	{
		id: "ela-residences",
		title: "Ela Residences",
		price: "AED 45,000,000",
		metaDesc:
			"Experience unparalleled luxury and seclusion at ELA Residences, nestled along a pristine 250-meter beachfront on the Eastern Crescent of the Palm Jumeirah. As the final masterpiece on this iconic destination, ELA Residences features Zaha Hadid's exquisite design, the opulence of Dorchester Collection management, and OMNIYAT's exceptional standards. Enjoy expansive terraces, private pools, and master wings with balconies and outdoor jacuzzis. Relax at the exclusive 2000 sq.m spa with world-class amenities, including private treatment rooms, a salon, snow room, vitality pools, and serene gardens.",
		description:
			"Embrace the seclusion of ELA Residences, nestled along an uninterrupted beachfront that stretches over 250 meters on the Eastern Crescent of the Palm Jumeirah. As the final, defining development on this iconic global destination, ELA Residences occupies one of the last exclusive locations available on this global icon.\n\nEla Residences offers an unmatched living experience, combining the brilliance of Zaha Hadid's design, the luxury management of Dorchester Collection, and the exceptional standards of OMNIYAT. Each residence features expansive outdoor terraces designed to entertain, offering seamless indoor-outdoor living spaces that create a fluid and open atmosphere. Enjoy the ultimate in relaxation with private pools in every residence and elevated sun lounging platforms for those perfect sun-soaked moments. The master wing offers its own private balcony, with selected units boasting an outdoor jacuzzi for an added touch of indulgence. Each bedroom is adorned with charming Juliette balconies, providing a personal retreat with a view. Experience unparalleled elegance and tranquility at Ela, where every detail is crafted for your utmost comfort and luxury.",
		type: "Residences",
		developer: "Omniyat",
		beds: 3,
		baths: 5,
		parking: 2,
		area: "5,586",
		completionYear: 2026,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Indulge in the ultimate relaxation with the unique, personalized sensorial experiences. The expansive 2000 sq.m spa and wellness center, operated by an exclusive branded partner, offers a plethora of world-class amenities. Enjoy 12 private treatment rooms, a luxurious hair and beauty salon, a rejuvenating snow room, experiential showers, steam and sauna rooms, multiple vitality pools, and serene private internal gardens",
		location: "Palm Jumeirah",
		address: "Palm Jumeirah - Dubai - United Arab Emirates",
		lat: "25.140587102294468",
		long: "55.139483480516034",
		coverImage: ElaCover,
		coverImageAlt: "Ela residences dining room",
	},

	{
		id: "armani-residences",
		title: "Armani Residences",
		price: "AED 21,000,000",
		metaDesc:
			"Discover luxury living at Armani Beach Residences, Palm Jumeirah. A collection of 2 to 5 bedroom homes with Armani/Casa fittings, personalized design, and unmatched elegance.",
		description:
			"Armani Beach Residences at Palm Jumeirah is a testament to the fusion of design brilliance and architectural excellence, creating an unparalleled residential offering that reflects the mastery of iconic Giorgio Armani and acclaimed architect Tadao Ando.\n\nComprising 2 to 5-bedroom residences, Armani Beach Residences epitomize luxury living with high-end fittings from Armani/Casa. Every living space and bedroom features meticulously crafted elements, including designer kitchens and bathrooms, wallpaper with metal profiles, metal skirting, and spotlights. Owners have the privilege of selecting between a light and dark design palette, allowing personalization of their living spaces.\n\nBedrooms are adorned with premium built-in closets and wardrobes, with many master bedrooms boasting spacious walk-in closets. The majority of residences include a family area, seamlessly connecting living spaces and bedrooms for a cohesive living experience. Additionally, each residence is equipped with a maid’s room and a guest bathroom, ensuring convenience and functionality.",
		type: "Apartment",
		developer: "Arada",
		beds: 2,
		baths: 4,
		parking: 2,
		area: "2,657",
		completionYear: 2026,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Discover a world of unparalleled amenities covering a sprawling 90,000 square feet, allowing residents to experience a truly ultra-luxury lifestyle.\nThis collection of high-end facilities includes a Premium residents-only spa with spacious gym, sauna ‧ and ice-bath. Lushly landscaped deck area, featuring large-scale infinity pool and separate children’s pool as well as a private beach, a movie theatre and children’s playroom.\nSignature lobby with 24-hour concierge, 24-hour security and valet services",
		location: "Palm Jumeirah",
		address: "Palm Jumeirah - Dubai - United Arab Emirates",
		lat: "25.138458167472816",
		long: "55.14657322471581",
		coverImage: ArmaniCover,
		coverImageAlt: "Armani residences dining room",
	},
	{
		id: "0017-utopia-damac-hills",
		title: "Utopia",
		price: "AED 24,000,000",
		description:
			"Experience the epitome of modern luxury at Utopia by Damac. These luxury villas are a seamless blend of geometric lines, locally-sourced woods, and cool metals, creating a contemporary haven. Enjoy privacy alongside sweeping golf course or lake views, complemented by refreshing breezes.\n\nChoose from three villa typologies, boasting five to seven spacious ensuite bedrooms. High ceilings, indigenous foliage, and rich marbles define the interiors, while show kitchens lead to grand dining rooms and cosy family areas.\n\nWith two-story living, a liveable roof floor, and well-equipped basements, each villa offers convenience with elevators, staff quarters, and multi-car garages with turntables.\n\nEach residence enjoys its own private infinity pool, basement entertainment arena, home cinema, water features, gym, spa...the list is as endless as the potential for a truly unique living experience.",
		type: "Villa",
		developer: "Damac",
		beds: 6,
		baths: 9,
		parking: 5,
		area: "13,583",
		completionYear: 2026,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Utopia villas are situated in a secluded enclave within DAMAC Hills, yet well-connected to the incredible amenities of the established, 42 million sqft community. From DAMAC Mall to the acclaimed Trump International Golf Club, Dubai, stables, skate park, Malibu Bay wave pool, luxury dining and entertainment options, multi-sports amenities and more.",
		location: "Damac Hills",
		address: "Damac Hills - Dubai - United Arab Emirates",
		lat: "25.025651224418866",
		long: "55.24402503662994",
		coverImage: UtopiaCover,
		coverImageAlt: "Utopia villa pool side",
	},
	{
		id: "0016-ellington-villa-la-mer",
		title: "Ellington Villa La Mer",
		price: "Ask for price",
		description:
			"Set amid the captivating coastal landscape of La Mer, this 7-bedroom villa by Ellington offers an incomparable living experience, seamlessly blending the sea's allure with contemporary design sophistication. With an infinity-edge pool, a unique glass-bottom rooftop pool, an exclusive acoustic cinema room, and a dedicated fitness studio, this villa epitomizes the fusion of luxury and modernity. Complete with 8 car parking spaces and integrated smart technology, this residence ensures an effortless living experience.\n\nResidents of this stunning villa are treated to a world of convenience and indulgence, with La Mer's vibrant array of gourmet restaurants, chic boutiques, and entertainment options just a stone's throw away. Whether savoring panoramic sea views from the villa's expansive outdoor spaces or immersing oneself in the rich cultural tapestry of La Mer's bustling community, this Beachside Oasis offers a lifestyle that seamlessly blends luxury, convenience, and the timeless allure of coastal living in one of Dubai's most coveted destinations.",
		type: "Villa",
		developer: "Ellington",
		beds: 7,
		baths: 9,
		parking: 8,
		area: "21,934",
		completionYear: 2024,
		paymentPlan: "70/30",
		amenitiesAndFeatures:
			"Feel aesthetics at every step. A minimalist light-coloured palette, high-quality finishes and textures embody sophistication at every touch. Captivating double-height spaces, seamlessly connected, showcase unrivalled elegance. Other features include but are not limited to:\nAn Infinity-edge pool with sub loungers - Rooftop pool with glass bottom -Acoustic cinema room - Fitness Studio - 8 car parking - Smart Home",
		location: "La Mer",
		address: "La Mer - Dubai - United Arab Emirates",
		lat: "25.228670",
		long: "55.249144",
		coverImage: EllingtonVillaCover,
		coverImageAlt: "Ellington Villa La Mer pool night",
	},
	{
		id: "0015-serenity-waterfront-collection",
		title: "Serenity Waterfront",
		price: "AED 40,000,000",
		description:
			"Designed with meticulous attention to detail, these waterfront mansions at Tilal al Ghaf is an architectural masterpiece by Nabile Gholam. Immerse yourself in the tranquil ambiance and the breathtaking views of the crystal lagoon, right from your doorstep. Every aspect of these mansions has been thoughtfully curated to offer an unparalleled living experience by the water's edge.\n\nThese waterfront mansions, with 6 to 7 bedrooms, embody a sense of luxury that surpasses expectations, creating an environment that seamlessly combines opulence with the calming allure of the lagoon. With project completion set for 2027, these magnificent villas represent a rare opportunity to own a piece of luxury. The 60/40 payment plan adds further convenience, making ownership of these prestigious homes more accessible.",
		type: "Villa",
		developer: "Majid Al Futtaim",
		beds: 6,
		baths: 8,
		parking: 7,
		area: "13,500",
		completionYear: 2027,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Tilal Al Ghaf is an exceptional community centred around a stunning lagoon bordered by white sandy beaches. The community features nearly 500,000 square metres of parks, green open spaces and treescapes, with walkable neighbourhoods interconnected by 18 kilometres of walking trails, and 11 kilometres of cycling tracks and jogging tracks, all set in lush landscaping.\nModern Cantilever Architecture - Home Elevators - Exclusive Bridge Access - Bespoke Landscape options",
		location: "Tilal al Ghaf",
		address: "Tilal Al Ghaf - Dubai - United Arab Emirates",
		lat: "25.022772",
		long: "55.219445",
		coverImage: SerenityWaterfrontCover,
		coverImageAlt: "Courtyard of Serenity Waterfront Villa",
	},
	{
		id: "0014-palm-jebel-ali-villas",
		title: "Palm Jebel Ali Villas",
		price: "Ask for price",
		description:
			"Nakheel's recent project, the new Palm Jebel Ali beach villas, is set to redefine the standard of luxurious coastal living in Dubai. With an expected completion date in 2027 and an 80/20 payment plan, these beachfront villas offer an enticing investment opportunity within this esteemed development. Uniquely designed to provide residents with an exclusive experience, each villa boasts its private beach access, embodying the epitome of waterfront luxury. Offering a range of meticulously designed villas, each residence is crafted to provide an exceptional standard of living that caters to the diverse needs and preferences of residents. Elegantly blending modern aesthetics with the serenity of the surrounding coastal landscape, these beach villas offer an unparalleled living experience.",
		type: "Villa",
		developer: "Nakheel",
		beds: 5,
		baths: 7,
		parking: 3,
		area: "7,876",
		completionYear: 2027,
		paymentPlan: "80/20",
		amenitiesAndFeatures:
			"Located on the exclusive fronts, each residence enjoys direct beach connectivity in this serene, private neighbourhood.\nThe front community focuses on offering an active lifestyle, with wide walkable streets allowing pedestrians and bikers to access the beach through pocketed parks.",
		location: "Palm Jumeriah",
		address: "Palm Jumeirah - Dubai - United Arab Emirates",
		lat: "25.003269",
		long: "54.978625",
		coverImage: PalmJebelAliCover,
		coverImageAlt: "Interior of Palm Jebel Ali Villa",
	},
	{
		id: "0013-bugatti-residences",
		title: "Bugatti Residences",
		price: "AED 28,600,000",
		description:
			"Welcome to a luxury oasis in Dubai, inspired by the elegance of the Riviera. \nThis prestigious development offers 182 units, including 171 Riviera-inspired mansions and 11 limited-edition sky mansion penthouses. With a harmonious blend of engineering excellence and refined aesthetics, these residences exude grace, power, and innovation. Enjoy spacious interiors with panoramic skyline views, along with amenities like a Riviera-inspired beach, private pool, fitness center, spa, and concierge services. Indulge in the epitome of opulence and sophistication in this extraordinary living experience.",
		type: "Apartment",
		developer: "Binghatti",
		beds: 3,
		baths: 5,
		parking: 0,
		area: "3,286",
		completionYear: 2026,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Indulge in a plethora of amenities including: \nRiviera Inspired Beach  -  Gymnasium & Training Services  -  VIP Owner Lounge  -  Private Pool  -  Concierge Service  -  Valet Service  -  SPA Service  -  Chauffeur Service  -  Butler Service",
		location: "Business Bay",
		address: "Business Bay - Dubai - United Arab Emirates",
		lat: "25.187812",
		long: "55.265438",
		coverImage: BugattiResidenceCover,
		coverImageAlt: "View of Dubai Marina from bugatti residence",
	},
	{
		id: "0012-como-residences",
		title: "Como Residences",
		price: "AED 53,000,000",
		description:
			"A collection of exceptionally spacious residences in the sky!\nThis exclusive collection of private residences offers a range of spacious layouts and exclusive features. Como Residences is your own personal beachfront haven. The awe-inspiring sunrise to sunset views will continuously amaze you. Relax and unwind in this remarkable beachfront paradise in the sky. Extremely spacious interiors lead outonto vast terraces embellished with lush greenery and unique private pools. Admire breathtaking views towards Burj Al Arab, Burj Khalifa, Palm Jumeirah, and Ain Dubai as you relax and breath in the fresh seaair. Each exclusive apartment enjoys either 180- or 360-degree panoramic views.",
		type: "Apartment",
		developer: "Nakheel",
		beds: 3,
		baths: 5,
		parking: 3,
		area: "9,490",
		completionYear: 2027,
		paymentPlan: "80/20",
		amenitiesAndFeatures:
			"Residents enjoy exclusive use of Como's unbeatable amenities:\nPrivate Lift Lobby to each residence - Private Sandy Beach Pool - 25M Lap Swimming Pool - Landscape Recreational Areas - Private Pools for Sky Terrace Apartments - Children's Wet and Dry Play Area - Separate Drivers Quarters - Paddle Courts & Squash Court - Private Café Lounge - Gym, Spa & Wellness Centre - Business Centre with Meeting Rooms - Concierge Services",
		location: "Palm Jumeriah",
		address: "Palm Jumeirah - Dubai - United Arab Emirates",
		lat: "25.110884555763448",
		long: "55.14585119849812",
		coverImage: ComoResidencesCover,
		coverImageAlt: "Living room of como residences",
	},
	{
		id: "0011-serenity-mansions",
		title: "Serenity Mansions",
		price: "AED 26,800,00",
		description:
			"Introducing Ultra Luxury Mansions by Majid Al Futtaim , Tilal Al Ghaf. Discover an extraordinary architectural masterpiece by Nabile Gholam, boasting a seamless facade and a central courtyard bathed in sunlight. This lavish property grants access to a private garden and showcases custom-designed courtyards with a climate-controlled swimming pool, exquisite landscaping, and a full lighting system. Enjoy the convenience of private lifts and indulge in the spa, home cinema, and gym. Immerse yourself in a world of opulence and unparalleled grandeur",
		type: "Mansion",
		developer: "Majid Al Futtaim",
		beds: 6,
		baths: 8,
		parking: 0,
		area: "11,000",
		completionYear: 2026,
		paymentPlan: "50/50",
		amenitiesAndFeatures:
			"Tilal Al Ghaf is an exceptional community centred around a stunning lagoon bordered by white sandy beaches. The community features nearly 500,000 square metres of parks, green open spaces and treescapes, with walkable neighbourhoods interconnected by 18 kilometres of walking trails, and 11 kilometres of cycling tracks and jogging tracks, all set in lush landscaping.",
		location: "Tilal al Ghaf",
		address: "Tilal Al Ghaf - Dubai - United Arab Emirates",
		lat: "25.023989673510055",
		long: "55.22439025562075",
		coverImage: SerenityMansionCover,
		coverImageAlt: "Front facade of serenity mansion",
	},
	{
		id: "0010-elysian-mansion",
		title: "Elysian Mansion",
		price: "AED 24,500,000",
		description:
			"Tailored for a select few, this lavish 5-bedroom mansion in Tilal al Ghaf encompasses a spacious 10,721 sqft plot and 10,672 sqft built-up area. It boasts a grand double-height entry, private elevator, and an underground 6-car gallery. The expansive living areas, modern kitchen, and guest suite make it ideal for both entertainment and relaxation. With triple-height courtyards, a private cinema, and a wellness studio, this property exudes luxury. En-suite bedrooms feature opulent marble and premium wooden flooring, while the integrated European appliances in the kitchen add a touch of sophistication. The elegant stone-cladding exterior and the private 28,000 sqft central park add to its allure. Indulge in the essence of exclusivity as every detail has been carefully curated to deliver a signature living experience.",
		type: "Mansion",
		developer: "Majid Al Futtaim",
		beds: 5,
		baths: 7,
		parking: 5,
		area: "10,672",
		completionYear: 2025,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Tilal Al Ghaf is an exceptional community centred around a stunning lagoon bordered by white sandy beaches. The community features nearly 500,000 square metres of parks, green open spaces and treescapes, with walkable neighbourhoods interconnected by 18 kilometres of walking trails, and 11 kilometres of cycling tracks and jogging tracks, all set in lush landscaping.",
		location: "Tilal al Ghaf",
		address: "Tilal Al Ghaf - Dubai - United Arab Emirates",
		lat: "25.022062",
		long: "55.224563",
		coverImage: ElysianMansionCover,
		coverImageAlt: "Front facade of elysian mansion",
	},
	{
		id: "0009-lamborghini-mansion",
		title: "Lamborghini Mansion",
		price: "AED 42,000,000",
		description:
			"This exclusive and futuristic 6-bedroom villa effortlessly reflect the lines and aesthetics of a mythical brand revered for its innovation and cutting-edge design. Beyond the meticulously finished interiors of the villa lies a majestic vista of Burj Khalifa and skyline, all visible from a private lounge overlooking the golf course.\n\nThe villa exude all the hallmarks of Automobili Lamborghini – from the futuristic, avant- garde architecture to the sleek and modern interiors. Private roof lounges offer vistas of the Burj Khalifa and surrounding golf course, just steps away from the expansive garden plots. There is also an option to furnish the villa exquisitely with Lamborghini inspired furniture.",
		type: "Villa",
		developer: "Emaar",
		beds: 6,
		baths: 8,
		parking: 3,
		area: "12,626",
		completionYear: 2024,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Reside at one of the most prestigious addresses in Dubai and enjoy all the best things on your doorstep. \n- Golf Course - Multipurpose fitness Trial - Kids Play Area - Tennis Court - Basketball Court - Football Pitch - Parks/Green Spaces - Picnic Areas - Dog Park",
		location: "Dubai Hills Estate",
		address: "Majestic Vistas - Dubai Hills Estate - United Arab Emirates",
		lat: "25.131823",
		long: "55.272233",
		coverImage: LamborghiniMansionCover,
		coverImageAlt: "Front facade of lamborghini mansion",
	},
	{
		id: "0008-orla-dorchester-collection",
		title: "Orla",
		price: "AED 52,223,850",
		description:
			"Nestled at the prime spot of the Palm's crescent, ORLA by Omniyat stands on a generous 29,000 square-meter beachfront parcel, offering an exclusive setting and uninterrupted panoramic views spanning 270 degrees. From the bustling skyline of Dubai to the serene turquoise waters of the Arabian Gulf, ORLA presents a picturesque vista that captures the essence of luxury living. \n\nFoster + Partners, the acclaimed architectural firm, spearheads the design of ORLA, ensuring a harmonious blend of contemporary elegance with the natural beauty of its surroundings. The development comprises 86 residences and 3 sky palaces, featuring a mix of two, three, and four-bedroom units gracefully suspended above the resort and the glistening sea. Each residence boasts a distinctive layout spread across one or two stories, meticulously designed to optimize space utilization and cater to the diverse preferences of its esteemed residents. With its strategic positioning and attention to detail, ORLA is set to redefine the concept of luxurious coastal living.",
		type: "Apartment",
		developer: "Omniyat",
		beds: 4,
		baths: 5,
		parking: 4,
		area: "6,532",
		completionYear: 2026,
		paymentPlan: "50/50",
		amenitiesAndFeatures:
			"Residents enjoy exclusive use of ORLA’s unbeatable amenities:\n  - Resident only beach club with indoor and outdoor dining - Large infinity temperature controlled outdoor pool - Indoor lap pool - Children’s pool and play area - Pet grooming salon - Spa with treatment rooms, salon, sauna & steam room - State-of-the-art fitness centre - Plunge pools and jacuzzis - Cycling and yoga studios - Business centre with 2 meeting rooms, boardroom and multi-functional events space - Library lounge - Cigar lounge - Private cinema - Bowling alley ",
		location: "Palm Jumeriah",
		address: "Crescent Rd - Palm Jumeriah - Dubai - United Arab Emirates",
		lat: "25.123188,55.",
		long: "55.112188",
		coverImage: OrlaCover,
		coverImageAlt: "Infinity pool",
	},
	{
		id: "0007-district-one-west",
		title: "District One West",
		price: "AED 60,000,000",
		description:
			"An urban oasis at the centre of the city. Discover a sublime, gated community with an exclusive collection of impeccable properties, ranging from four-bedroom villas to seven-bedroom mansions. With an abundance of lush, green outdoor spaces,the dazzling Crystal Lagoon, and an extensive array of world-class amenities, this inspiring neighbourhood offers unprecedented levels of luxury living. You can enjoy the privacy and safety of living in one of the most exclusive communities in Dubai, whilst being minutes away from the best that the city has to offer.\n\nIn District One West, there is an ideal property to suit every need. Choose an exclusive villa set looking out over the dazzling waters of the Crystal Lagoon, or a home surrounded by the flourishing green parks and gardens. Spacious properties ranging from four-bedroom villas to seven-bedroom mansions are available. Each is a property of architectural distinction and impeccable style. With exquisite design and finishes of the highest standard, appreciate every fine detail as you relax in your luxurious new home.",
		type: "Mansion",
		developer: "Nakheel",
		beds: 7,
		baths: 9,
		parking: 6,
		area: "20,800",
		completionYear: 2027,
		paymentPlan: "80/20",
		amenitiesAndFeatures:
			"The mansion villas are spacious and elegant properties, carefully positioned along the waterfront of the captivating Crystal Lagoon. The beautiful homes face onto the water with the pristine sands and boardwalk just steps away from your backdoor.\nFloor to ceiling double height windows, sublime materials, plush interiors, beautiful gardens, separate service zones, large guest suites, and glistening private pools.",
		location: "District One, MBR",
		address: "District One, MBR - Dubai - United Arab Emirates",
		lat: "25.152437",
		long: "55.279562",
		coverImage: DistrictOneWestCover,
		coverImageAlt: "Pool side",
	},
	{
		id: "0006-vela-dorchester-collection",
		title: "Vela",
		price: "AED 36,508,500",
		description:
			"VELA is a new luxury residential tower located in the Burj Khalifa District in Dubai. It is developed by OMNIYAT, a prominent developer in the city. The tower is part of OMNIYAT's waterfront destination, which aims to redefine Marasi Bay.\n\nStanding tall at 150 meters, VELA offers a total gross floor area of 272,860 square feet spread across 30 floors. It comprises 38 residences, including opulent penthouses and three- to four-bedroom properties. Some of the residences feature double-height living spaces and private terrace pools, adding to the luxurious experience. At the top of the tower is the Sky Palace, spanning 22,992 square feet over three floors. It boasts two swimming pools, grand terraces, a private gym, and a Majlis, providing residents with exclusive amenities and breathtaking views.\n\nWith Foster + Partners' architectural expertise and Gilles and Boissier's exquisite interior design, VELA promises to be a visual masterpiece that seamlessly integrates with its stunning surroundings. It represents a union of architectural brilliance and artistic refinement, truly setting a new standard for luxury living in Dubai's Burj Khalifa District.",
		type: "Residences",
		developer: "Omniyat",
		beds: 3,
		baths: 5,
		parking: 2,
		area: "5,988",
		completionYear: 2027,
		paymentPlan: "60/40",
		amenitiesAndFeatures:
			"Exclusive access to the - Ultra-luxe beach club at One at Palm Jumeirah - Main, VIP & Marina Lobby - Infinity-Edge Lap Pool  - Triple-Height Gym - Spa Suite and Salon - Kids Room - Cinema Room - Meeting Rooms & Lounge - Security - Valet - Hotel facilities at The Lana, Dorchester Collection, Dubai - Beach club at One at Palm Jumeirah, Dorchester Collection Dubai",
		location: "Marasi Bay, Dubai",
		address: "Marasi Bay - Business Bay - Dubai - United Arab Emirates",
		lat: "25.190062",
		long: "55.289187",
		coverImage: VelaCover,
		coverImageAlt: "View of dubai skyline",
	},
	{
		id: "0005-lanai-islands-estates",
		title: "Lanai Islands' Estates",
		price: "AED 65,000,000",
		description:
			"Exceptional Homes for an elevated island resort experience. Lanai Islands offer the idyllic lifestyle that only a private resort-style retreat in the heart of nature can offer, yet remains within easy reach of Dubai’s primary business and entertainment hubs.An exclusive 13 ultra-premium estate homes crafted with architectural integrity, inspired by the tranquil beauty of nature and the surrounding aqua waters\n\nA modern architectural masterpiece designed by Starchitect SAOTA and interior design by multi-award winner Kelly Hoppen. Lanai Islands is the latest residential development within the Tilal Al Ghaf master community and is the epitome of a resort living lifestyle.Escape to your own private island of secluded luxury with your own spa pool surrounded by nature’s finest greenery facing the majestic azure waters of a private Lagoon.",
		type: "Mansion",
		developer: "Majid Al Futtaim",
		beds: 7,
		baths: 9,
		parking: 10,
		area: " 23,652",
		completionYear: 2026,
		paymentPlan: "50/50",
		amenitiesAndFeatures:
			"Tilal Al Ghaf is an exceptional community centred around a stunning lagoon bordered by white sandy beaches. The community features nearly 500,000 square metres of parks, green open spaces and treescapes, with walkable neighbourhoods interconnected by 18 kilometres of walking trails, and 11 kilometres of cycling tracks and jogging tracks, all set in lush landscaping.\n-Modern Cantilever Architecture - Home Elevators - Exclusive Bridge Access - Bespoke Landscape options",
		location: "Tilal al Ghaf",
		address: "Tilal Al Ghaf - Dubai - United Arab Emirates",
		lat: "25.024313",
		long: "55.226437",
		coverImage: LanaiIslandsCover,
		coverImageAlt: "Backyard of Lanai Islands Estates",
	},
	{
		id: "0004-ava-at-palm-jumeirah",
		title: "Ava",
		price: "AED 78,000,000",
		description:
			"Only 17 residences in total, defining AVA at Palm Jumeirah as the most exclusive residential address in Dubai. AVA at Palm Jumeirah Dubai’s organic form brings a distinctive silhouette to Dubai’s skyline. The crown of AVA at Palm Jumeirah is devoted to one of the most sumptuous mega penthouses in the world.Unique in the truest sense of the word, this residence unites over multiple levels, boasting true elegance in design with a dedication to ensuring no detail is ignored.\n\nThe Sky Palace creates unequivocal space, volume and opportunity to truly explore the limits of what a life well-lived can be in AVA at Palm Jumeirah, Dorchester Collection, Dubai.Spread over four floors, this incomparable 33,406 sq. ft. residence will garner global attention from those who covet a day in the life of the owner.\n\nThe Sky Palace at AVA at Palm Jumeirah, Dorchester Collection, Dubai, affords its own private amenities, alongside exclusive access to the amenities of AVA at Palm Jumeirah, plus working in harmony with its neighbouring tower, One at Palm Jumeirah, also managed by Dorchester Collection.",
		type: "Sky Palace ",
		developer: "Omniyat",
		beds: 5,
		baths: 7,
		area: "33,406",
		completionYear: 2026,
		paymentPlan: "50/50",
		amenitiesAndFeatures:
			"Unbeatable amenities including an infinity edge pool, gym, spa, boardroom, cinema room and yoga studio. Shared amenities with neighbouring iconic One at Palm Jumeirah, also managed by Dorchester Collection. including beach, restaurant and jetty access. \nFull floor units with floor to ceiling windows - 270 degree views - private terrace with a pool to each unit. \nServices managed by Dorchester Collection, including valet parking, doorman and porter facilities, concierge, maintenance of common areas as well as additional à la carte services. \nDramatic & elegant arrival experience over edgeless bodies of water with a waterfall entrance and skyline views",
		location: "Palm Jumeriah",
		address: "The Palm Jumeirah - Dubai - United Arab Emirates",
		lat: "25.103313",
		long: "55.150688",
		coverImage: AvaCover,
		coverImageAlt: "View of dubai skyline",
	},
	{
		id: "0003-sensoria-at-luxe-jbr",
		title: "Sensoria",
		price: "AED 15,000,000",
		description:
			"A masterfully-designed residences and an undeniable showpiece within FIVE LUXE, a prime beach property in the heart of the Jumeirah Beach Residence with sweeping sunset vistas from the landmark Dubai Eye to the lush Palm Jumeirah. An architectural masterpiece, Sensoria is enviably juxtaposed a foot step from the beach with unrestricted Dubai skyline views and FIVE LUXE - creating an unquestionable showpiece for the discerning luxury aficionado. Unrivalled living spaces that encompass grand scale 3.5m clear high ceilings and fully glazed windows throughout that open up to expansive outdoor entertainment spaces and ocean-facing pools, Sensoria is undoubtedly the ultimate FIVE-styled residence.\n\nSensoria’s opulent environment uses a monochromatic colour palette, minimalist clean lines, natural materials, and free-flowing light for a unique design journey with a prime selection of luxury branded furniture and distinctive designer pieces, Bespoke branded kitchens with high-end appliances and chic bathrooms with natural stone curved bathtubs that cater for extraordinary comfort. To put it succinctly, this is what living a decadently luxurious life is all about - every single day.",
		type: "Apartment",
		developer: "Five Holdings",
		beds: 3,
		baths: 4,
		parking: 2,
		area: "3,215",
		completionYear: 2024,
		paymentPlan: "30/70",
		amenitiesAndFeatures:
			"Residents enjoy exclusive use of Five’s unbeatable amenities inlcuding but not limited to:\nPrivate Beach and Social Pool - Indoor Pool and Gym - Outdoor Gym - Paddle Court - Kids Club & Pool - Ocean facing, private pool with every residencde",
		location: "Jumeriah Beach Residence (JBR)",
		address: "Jumeriah Beach Residence (JBR) - Dubai - United Arab Emirates",
		lat: "25.082187",
		long: "55.135188",
		coverImage: SensoriaCover,
		coverImageAlt: "Living room of the apartment",
	},
	{
		id: "0002-bayview-by-address-resorts",
		title: "Bayview",
		price: "AED 28,000,000",
		description:
			"Elevating the definition of branded luxury seaside living. Bayview by Address Resorts is a luxury seaside living gem. This cutting-edge landmark offers a curated collection of luxurious branded, opulently-furnished apartments and penthouses, as well as a private beach and a platform of world-class amenities tailored to your every need.\n\nDiscover Bayview by Address Resorts, where a diverse range of apartments awaits, ranging from one to four bedrooms, along with luxurious four and five-bedroom penthouses, all showcasing stunning views. Enjoy the serenity of beachfront living, enhanced by the exceptional coastal location, visionary architecture, innovative interiors, and lavish furnishings. The layouts of these residences offer a timeless lifestyle, featuring seamless flow, expansive living spaces, and thoughtfully chosen natural materials and upscale finishes. With sophisticated elevators, top-tier security, exclsive access, covered parking, and exceptional amenities, experience absolute tranquility and privacy",
		type: "Apartment",
		developer: "Emaar",
		beds: 6,
		baths: 8,
		parking: 4,
		area: "4462",
		completionYear: 2027,
		paymentPlan: "90/10",
		amenitiesAndFeatures:
			"Indulge in a plethora of amenities conveniently located near you. The amenities podium is an exclusive haven reserved for residents. It provides ample opportunities for leisure and rejuvenation amidst the serene blue skies. Experience the ultimate resort-like lifestyle with a harmonious blend of luxurious amenities and breathtaking outdoor activities. \n- Infinity Pool and Wet Deck - Shaded Pool Deck - Sun Loungers - Shaded Kids’ Pool Area & Play Area  - Raised Lawn and BBQ Pods  - Shaded Communal Areas  - Ground-Level Boutiques & Cafes  - Landscaped Garden",
		location: "Dubai Harbour",
		address: "Bayview by Address - Dubai Harbour - United Arab Emirates",
		lat: "25.096466",
		long: "55.137605",
		coverImage: BayviewCover,
		coverImageAlt: "Pool side",
	},
	{
		id: "0001-six-senses-residences",
		title: "Six Senses",
		price: "AED 39,500,000",
		description:
			"Six Senses Residences is a luxury residential development located on the iconic Palm Jumeirah Island in Dubai. The project offers an exclusive collection of waterfront apartments and penthouses, designed to provide residents with the ultimate in luxury living.\n\nThe development is comprised of three low-rise buildings, each with a unique architectural design that reflects the natural beauty of the surrounding environment. The buildings are situated along a private beachfront and are surrounded by lush green gardens and palm trees, providing residents with a tranquil and serene atmosphere.\n\nThe apartments and penthouses at Six Senses Residences are designed with a contemporary aesthetic, featuring high-quality finishes and luxury fittings throughout. Each residence boasts expansive living areas and spacious outdoor terraces, providing residents with stunning views of the Arabian Gulf and Dubai's iconic skyline. Residents will have access to a range of exclusive amenities, including a state-of-the-art fitness center, a spa, and wellness center, and a variety of dining options. The development also features a private marina, providing residents with direct access to the Arabian Gulf and a range of water-based activities.\n\nThe location of Six Senses Residences is also a major drawcard, situated on the Palm Jumeirah Island, one of Dubai's most sought-after residential addresses. The island offers a wealth of amenities, including world-class restaurants, shopping, and entertainment options.",
		type: "Sky Villa",
		developer: "Select Group",
		beds: 4,
		baths: 5,
		parking: 4,
		area: "6,448",
		completionYear: 2024,
		paymentPlan: "60/40",
		location: "Palm Jumeriah",
		address: "Palm Jumeriah Rd - Dubai - United Arab Emirates",
		lat: "25.10044",
		long: "55.11773",
		coverImage: SixSensesCover,
		coverImageAlt: "Pool side",
	},
];
export default data;
